
import { defineComponent, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useMutationCommon from '@/hooks/useMutationCommon'
import useOpenInfo from '@/hooks/openInfo'
import { setApptitle } from '@/utils/native-app'
import * as storage from '@/utils/storage'

export default defineComponent({
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { openType, step, title, saveCommonOfVuex } = useOpenInfo()

    const isShowSkip = computed(() => route.query?.hide)

    // 获取storage中的formType,用来区分普通开户和投资移民户
    const formTypeVal = computed(() => storage.get('formType'))
    const steps = computed(() => {
      return [
        { title: '绑定香港银行卡', remark: '与本人上传证件姓名相同' },
        { title: '转账', remark: formTypeVal.value === 'openAccount' ? '单笔金额不低于10,000港币或1,500美元' : '单笔金额不低于10,000港币或1,500美元' },
        { title: '通知我们', remark: '上传转账凭证' }
      ]
    })

    // 保存下一步调用接口
    const saveNext = () => {
      const common = {
        openType: openType.value,
        openStatus: 1,
        btnText: '身份核实',
        remark: '距离开户成功只差最后一步',
        step
      }

      useMutationCommon(common).then(() => {
        saveCommonOfVuex({ info: common })
        router.push({ name: 'bind-card' })
      })
    }
    const handleNext = () => {
      saveNext()
    }

    const skip = () => {
      router.push({ name: 'account-opening-statement' })
    }

    onMounted(() => {
      setApptitle(title)
    })

    return { steps, handleNext, skip, isShowSkip }
  }
})
